/**
 * @file alert
 */
// import {gsap} from 'gsap';

jQuery.noConflict();
( function( $ ) {
	$( document ).ready( function() {
		/**
		 * Show Alert
		 */
		function showAlert() {
			const alertBar = document.querySelector( '.v-alert' );
			if ( ! alertBar ) {
				return;
			}

			let alertID = alertBar.dataset.id;
			if ( ! alertID ) {
				return;
			}

			const close = alertBar.querySelector( '.v-alert__close' );

			if ( close ) {
				close.addEventListener( 'click', () => {
					alertBar.classList.add( 'v-alert--hidden' );
					alertBar.removeAttribute( 'style' );
					localStorage.setItem( 'apc-alert-' + alertID, 'dismissed' );
				} );
			}

			alertID             = alertID.replace( ' ', '-' );
			const wasShown      = sessionStorage.getItem( 'apc-alert-' + alertID );
			const isDismissable = alertBar.dataset.dismissable;
			const wasDismissed  = localStorage.getItem( 'apc-alert-' + alertID );

			if ( ( wasShown && isDismissable ) || wasDismissed ) {
				return;
			}

			alertBar.classList.remove( 'v-alert--hidden' );
			sessionStorage.setItem( 'apc-alert-' + alertID, 'seen' );
		}

		showAlert();
	} );
}( jQuery ) );
